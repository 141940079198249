import { For } from "solid-js";
import { appHomepageScreenshot } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

const promises = [
  {
    text: "Instant gift card e-delivery here & on WhatsApp",
    icon: "lightning",
  },
  {
    text: "Saved ₹10Cr till date by 10L+ users",
    icon: "piggy-bank",
  },
  {
    text: "Safe & secure",
    icon: "shield-check",
  },
];

export default function ExploreBrands() {
  const redirectToWeb = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div class="bg-red w-full rounded-2xl border border-solid border-[#DDD] p-1">
      <div class="rounded-xl border border-solid border-[#EDEEF1] bg-gradient-to-b from-[#EDEEF1] from-0%  to-[#FFF] to-100%	p-4">
        <div class="flex flex-col items-center">
          <div class="relative  flex h-48  w-full justify-center rounded-xl border border-[#DDD] bg-white px-4">
            <img
              src={appHomepageScreenshot}
              class="absolute bottom-0 h-[148px] "
              alt="Hubble app screenshot"
            />
          </div>
          <Spacer height={12} />
          <span class="w-full text-left text-h4 font-semibold	">
            Enjoy instant discount on
            <br /> 400+ brands with Gift cards
          </span>
          <Spacer height={12} />
          <div class="flex flex-col gap-2 self-baseline">
            <For each={promises}>
              {(promise) => (
                <div class="inline-flex items-center gap-1">
                  <PhosphorIcon
                    fontSize={15}
                    name={promise.icon}
                    size="fill"
                    class="text-green-600"
                  />
                  <div class="text-mediumBold text-basePrimaryDark">
                    {promise.text}
                  </div>
                </div>
              )}
            </For>
          </div>
          <Spacer height={12} />
          <button
            onClick={() => {
              redirectToWeb("https://www.myhubble.money/");
            }}
            class="text-sm my-2 w-full rounded-full bg-basePrimaryDark py-3 text-white disabled:bg-gray-400"
          >
            Explore Brands
          </button>
        </div>
      </div>
    </div>
  );
}
