"use server";

import { getRequestEvent } from "solid-js/web";
import { getStaticProxyJson } from "../static";
import { FootNote } from "../types/brand";

export type GiftCardBalanceRouteData = {
  isLoggedIn: boolean;
  staticContext: { content: FootNote[] };
};

export const getGiftCardBalanceRouteData =
  async (): Promise<GiftCardBalanceRouteData> => {
    const staticContext = await getStaticProxyJson("gift-card-balance.json");

    return {
      isLoggedIn: getRequestEvent()?.locals.sid !== undefined,
      staticContext,
    };
  };
