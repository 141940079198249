import { useSearchParams } from "@solidjs/router";
import {
  ErrorBoundary,
  JSX,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { HubbleError } from "~/components/error";
import useScript from "~/utils/hooks/use_script";
import { getGiftCardBalance } from "~/server/apis/client_apis";
import { GiftCardBalance } from "~/server/types/brand";
import { ThreeDotLoader } from "../../widgets/button";
import { hasNonNullOrEmptyValue } from "~/utils/common";
import { HubbleEvent, webEventManager } from "~/data/events";
import { captureErrorInSentry } from "~/utils/third_party/sentry";

export default function CheckBalance() {
  const [queryParams, setQueryParams] = useSearchParams();
  const initialCardNumber = queryParams.cardNo || "";
  const initialCardPin = queryParams.cardPin || "";

  const formatCardNumber = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/g, "").replace(/(.{4})(?=.)/g, "$1-");
  };

  const [checkBalance, setCheckBalance] = createSignal({
    token: "",
    cardNo: formatCardNumber(initialCardNumber),
    cardPin: initialCardPin,
  });

  const [isVerified, setIsVerified] = createSignal(false);
  const [isSubmitting, setIsSubmitting] = createSignal(false);
  const [isFormValid, setIsFormValid] = createSignal(false);
  const [recaptchaDisabled, setRecaptchaDisabled] = createSignal(true);

  const cardBalanceResponseInitialValue = {
    message: "",
    balance: null,
    expiry: null,
    isValid: null,
  };

  const [cardBalanceResponse, setCardBalanceResponse] =
    createSignal<GiftCardBalance>(cardBalanceResponseInitialValue);

  const handleRecaptchaChange = (token: string) => {
    setCheckBalance((prev) => ({ ...prev, token }));
    setIsVerified(true);
  };

  const handleRecaptchaExpired = () => {
    setCheckBalance((prev) => ({ ...prev, token: "" }));
    setIsVerified(false);
  };

  const validateForm = () => {
    const { cardNo, cardPin } = checkBalance();

    const isCardNumberValid = /^[a-zA-Z0-9-]{16}$/.test(
      cardNo.replace(/-/g, "")
    );
    const isCardPinValid = /^[A-Za-z0-9]{6,}$/.test(cardPin);

    const isFormValid = isCardNumberValid && isCardPinValid;
    setIsFormValid(isFormValid);
    setRecaptchaDisabled(!isFormValid);
  };

  createEffect(() => {
    validateForm();
  });

  const handleInputChange: JSX.EventHandler<HTMLInputElement, InputEvent> = (
    event
  ) => {
    const { id, value } = event.currentTarget;
    let formattedValue = value;
    if (id === "cardNo") {
      formattedValue = formatCardNumber(value);
    }
    setCheckBalance((prev) => ({ ...prev, [id]: formattedValue }));
    setQueryParams({ [id]: value.replace(/-/g, "") });
    setCardBalanceResponse(cardBalanceResponseInitialValue);
    setRecaptchaDisabled(!isFormValid());
  };

  const handleSubmit: JSX.EventHandler<HTMLFormElement, Event> = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setCardBalanceResponse(cardBalanceResponseInitialValue);
    submitForm();
  };

  const submitForm = async () => {
    if (!isFormValid()) {
      alert("Form is not valid!");
      setIsSubmitting(false);
      return;
    }

    let balanceDetails: GiftCardBalance | null = null;

    try {
      const { token, cardNo, cardPin } = checkBalance();
      balanceDetails = await getGiftCardBalance(
        token,
        cardNo.replace(/-/g, ""),
        cardPin
      );

      setCardBalanceResponse({
        balance: balanceDetails.balance,
        message: balanceDetails.message,
        expiry: balanceDetails.expiry,
        isValid: balanceDetails.isValid,
      });
      webEventManager.sendEvent(HubbleEvent.CHECK_GIFT_CARD_BALANCE_STATUS, {
        status: "success",
      });
    } catch (error: unknown) {
      setCardBalanceResponse({
        balance: balanceDetails?.balance ?? null,
        message: balanceDetails?.message ?? "",
        expiry: balanceDetails?.expiry ?? null,
        isValid: balanceDetails?.isValid ?? null,
      });
      webEventManager.sendEvent(HubbleEvent.CHECK_GIFT_CARD_BALANCE_STATUS, {
        status: "failure",
        error: error,
      });
    } finally {
      resetForm();
      // @ts-ignore
      window.grecaptcha.reset();
    }
  };

  const resetForm = () => {
    setIsSubmitting(false);
    setCheckBalance((previousCardBalance) => ({
      ...previousCardBalance,
      token: "",
    }));
    setIsVerified(false);
  };

  const renderRecaptcha = () => {
    // @ts-ignore
    if (typeof window !== "undefined" && window.grecaptcha) {
      // @ts-ignore
      window.grecaptcha.render("recaptcha-container", {
        sitekey: "6LfRZfgpAAAAAB57Oym83dtEqwg_NgOSXiU2A9s_",
        callback: handleRecaptchaChange,
        "expired-callback": handleRecaptchaExpired,
      });
    }
  };

  useScript(
    "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
  );

  onMount(() => {
    // @ts-ignore
    window.onloadCallback = renderRecaptcha;

    webEventManager.sendEvent(HubbleEvent.VISIT_BALANCE_CHECK, {
      cardNo: queryParams.cardNo,
      cardPin: queryParams.cardPin,
      brandKey: queryParams.brandKey,
    });
  });

  const getFieldErrorMessage = (field: string) => {
    if (
      field === "cardNo" &&
      checkBalance().cardNo.length > 0 &&
      !/^[a-zA-Z0-9-]{19}$/.test(checkBalance().cardNo)
    ) {
      return "Card number must be 16 characters.";
    }
    if (
      field === "cardPin" &&
      checkBalance().cardPin.length > 0 &&
      !/^[a-zA-Z0-9]{6,}$/.test(checkBalance().cardPin)
    ) {
      return "Card PIN must be at least 6 characters.";
    }
    return "";
  };

  return (
    <ErrorBoundary
      fallback={(err) => {
        captureErrorInSentry(err);
        return <HubbleError errorMessage={err.message} />;
      }}
    >
      <div class="flex w-full items-center justify-center">
        <div class="w-full rounded-2xl border border-gray-200 bg-white p-4 shadow-base md:p-6">
          <form onSubmit={handleSubmit}>
            <label
              for="cardNo"
              class="mt-0 block text-bold font-medium text-black md:my-2"
            >
              Card number
            </label>
            <input
              type="text"
              id="cardNo"
              class="my-3 w-full rounded-lg border border-gray-300 p-3 focus:ring focus:ring-gray-200 disabled:pointer-events-none disabled:opacity-50 md:my-1"
              placeholder="Enter card number"
              onInput={handleInputChange}
              value={checkBalance().cardNo}
              disabled={isVerified()}
            />
            <Show
              when={
                !hasNonNullOrEmptyValue(cardBalanceResponse()) &&
                getFieldErrorMessage("cardNo")
              }
            >
              <p class="text-xs text-red-600">
                {getFieldErrorMessage("cardNo")}
              </p>
            </Show>
            <div class="md:h-3" />
            <label
              for="cardPin"
              class="my-2 block text-bold font-medium text-black"
            >
              Card PIN
            </label>
            <input
              type="text"
              id="cardPin"
              class="mb-1 mt-1 w-full rounded-lg border border-gray-300  p-3 focus:ring focus:ring-gray-200 disabled:pointer-events-none disabled:opacity-50"
              placeholder="Enter card PIN"
              onInput={handleInputChange}
              value={checkBalance().cardPin}
              disabled={isVerified()}
            />
            <Show
              when={
                !hasNonNullOrEmptyValue(cardBalanceResponse()) &&
                getFieldErrorMessage("cardPin")
              }
            >
              <p class="text-xs text-red-600">
                {getFieldErrorMessage("cardPin")}
              </p>
            </Show>

            <div
              class={`mx-0 my-4 flex min-h-20 max-w-full justify-start  ${!isFormValid() || recaptchaDisabled() ? `pointer-events-none cursor-not-allowed opacity-50` : ""}`}
            >
              <div
                id="recaptcha-container"
                class={`g-recaptcha w-[17rem] origin-top-left scale-[0.85] md:scale-[1]`}
              ></div>
            </div>

            <Show
              when={!hasNonNullOrEmptyValue(cardBalanceResponse())}
              fallback={<></>}
            >
              <button
                type="submit"
                class={`my-2 flex w-full justify-center rounded-full bg-black py-3 md:w-1/3 md:py-4 ${isSubmitting() || isVerified() ? "bg-black" : "bg-gray-200"} font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-baseSecondaryMedium `}
                disabled={!isFormValid() || isSubmitting() || !isVerified()}
              >
                {isSubmitting() ? (
                  <ThreeDotLoader color={"#000"} />
                ) : (
                  <p>Check balance</p>
                )}
              </button>
            </Show>

            <Show when={cardBalanceResponse().balance ?? 0 > 0}>
              <div class="text-center md:text-start">
                <h2 class="text-mediumBold">Your gift card balance</h2>
                <p class="text-h3 text-green-700">
                  ₹{cardBalanceResponse().balance}
                </p>
              </div>
            </Show>

            <Show
              when={
                cardBalanceResponse()?.isValid === false &&
                cardBalanceResponse()?.message === "Card Expired" &&
                cardBalanceResponse()?.balance === 0 &&
                cardBalanceResponse()?.expiry !== null
              }
              fallback={<></>}
            >
              <div class="text-center md:text-start">
                <h2 class="text-mediumBold">Your gift card balance</h2>
                <p class="text-h3 text-errorDark">Expired</p>
              </div>
            </Show>

            <Show
              when={
                cardBalanceResponse()?.isValid === false &&
                cardBalanceResponse()?.message.length > 0 &&
                cardBalanceResponse()?.balance === null &&
                cardBalanceResponse()?.expiry === null
              }
              fallback={<></>}
            >
              <div class="text-center md:text-start">
                <p class="text-bold text-errorDark">Failed to check balance.</p>
                <p class="text-mediumBold">{cardBalanceResponse()?.message}</p>
              </div>
            </Show>
          </form>
        </div>
      </div>
    </ErrorBoundary>
  );
}
